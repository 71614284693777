import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import('../views/IndexView.vue')
  },
  {
    path: '/actualidad',
    name: 'actualidad',
    component: () => import('../views/ActualidadView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  //Hacer scroll a un elemento especificado con <router-link :to="{ name: '', hash: '' }"
  scrollBehavior(to, from, savedPosition) {
    let position = {}
    if (to.hash) {
      position = {
        el: to.hash,
        behavior: 'smooth'
      }
    }
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(position)
      }, 100)
    })
  }
})

export default router
