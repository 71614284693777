<template>

    <nav class="flex flex-wrap justify-between w-full lg:flex-col lg:justify-end bg-azul_1 lg:bg-azul_2">
        <header class="hidden lg:flex lg:h-28 bg-azul_1">
            <div class="items-center justify-between hidden w-full text-white md:container md:mx-auto 2xl:px-56 lg:flex lg:mt-0">
                <img src="./assets/img/logo-dgtic.png" class=" w-[250px]" alt="DGTIC UNAM">
                <p class="text-[26px] font-roboto font-normal text-white mb-0">Dirección de Colaboración y Vinculación</p>
            </div>
        
        </header> 

        <h1 class="lg:hidden">
            <img src="./assets/img/logo-dgtic.png" class=" w-[150px] pl-5 mt-[8px] mb-[8px] " alt="DGTIC UNAM">
        </h1>
        <div class="self-center block lg:hidden">
            <button onclick="most()" class="flex items-center px-3 py-2 mr-4 text-white border rounded">
            <svg class="w-3 h-3 fill-current" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/></svg>
            </button>
        </div>
        <div id="menu" class="justify-end flex-grow hidden p-3 border-t border-black lg:flex lg:items-center lg:w-auto bg-azul_2 lg:bg-transparent lg:border-0">
            <div class="w-full text-xs text-center ">

                <router-link :to="{ name: 'index', hash: '#cont1' }" onclick="ocultarNavbar()" class="block mt-4 mr-6 text-white lg:inline-block lg:mt-0" >
                    Quiénes somos
                </router-link>
                <router-link :to="{ name: 'index', hash: '#cont2' }" onclick="ocultarNavbar()" class="block mt-4 mr-6 text-white lg:inline-block lg:mt-0" >
                    Servicios externos
                </router-link>
                <router-link :to="{ name: 'index', hash: '#cont3' }" onclick="ocultarNavbar()" class="block mt-4 mr-6 text-white lg:inline-block lg:mt-0" >
                    Servicios internos
                </router-link>
                <router-link :to="{ name: 'index', hash: '#cont4' }" onclick="ocultarNavbar()" class="block mt-4 mr-6 text-white lg:inline-block lg:mt-0" >
                    Toda la UNAM en Línea
                </router-link>
                <router-link to="/actualidad" onclick="ocultarNavbar()" class="block mt-4 mr-6 text-white lg:inline-block lg:mt-0">
                    Actualidad
                </router-link>
                <a href="http://www.becas.software.unam.mx/" onclick="ocultarNavbar()" class="block mt-4 text-white lg:inline-block lg:mt-0" target="_blank" title="Dirigirse al sitio web de Becas en Ingeniería de Software">
                    Becas en Ingeniería de Software
                </a>
                
            </div>
        </div>
    </nav>

  <router-view/>

  <footer class="mt-10 bg-azul_1">
        <div id="pie" class="container mx-auto px-8 2xl:px-56 md:flex text-white text-[11px] font-normal py-10">
            <div  class="w-full mb-3 text-left md:w-1/3 md:mr-3">
              
                    <img src="./assets/img/logo-dgtic.png" class=" w-[180px] mb-10" alt="Emblema de la DGTIC">		
                    <div> 
                        <!--<p class="pb-3.5 text-sm font-bold text-white">
                            <a href="https://www.tic.unam.mx/avisosprivacidad/">Avisos de privacidad de la DGTIC</a>
                        </p>-->
                        <p class="text-sm font-bold text-white">
                            <a href="http://www.ifc.unam.mx/pdf/codigo-etica-unam.pdf" target="_blank" rel="noopener">Código de ética de la UNAM</a>
                       </p>
                    </div>
                
            </div> 
            <div class="w-full mb-3 text-left md:w-1/3 md:mb-0">
                
                    <p class="mb-3 text-sm leading-5 text-white md:mb-0" ><span class="font-bold">Ubicación</span>
                        <br />
                        Circuito escolar s/n,<br>
                        Edificio IIMAS, planta baja, ala norte,<br>
                        Ciudad Universitaria, <br>
                        C.P. 04510, CDMX.
                    </p>               
           
            </div> 

        </div>
                
        <div class="bg-azul_2 text-[10px] text-white text-center mt-10">
            <p class="container px-8 py-4 mx-auto mb-0 text-xs leading-tight text-white wrapper 2xl:px-56">     
                Hecho en México. Universidad Nacional Autónoma de México (UNAM). Todos los derechos reservados 2022.<br>
                Esta página puede ser reproducida con fines no lucrativos, siempre y cuando se cite la fuente
                completa y su dirección electrónica, y no se mutile; de otra forma requiere permiso previo por
                escrito de la institución.
            </p> 
        </div>

    </footer>

</template>